import React from 'react';
import './App.css';
import { Helmet } from 'react-helmet'; // <-- Import Helmet

import janetdavey from './janetdavey.jpeg';

function App() {
    return (
        <div className='container w-full'>
            <div className='flex flex-col w-full items-center justify-center'>
                <Helmet>
                    <title>Janet Davey - Composer/Pianist/Poet</title>
                </Helmet>

                <header className='flex flex-col items-center justify-center w-full text-center py-40 gap-12'>
                    <h1 className='text-6xl font-bold'>Janet Davey</h1>
                    <h2 className='text-italic text-xl'>
                        composer/pianist/poet
                    </h2>
                    <p className='text-xl mb-20'>
                        Her words and music{' '}
                        <span className='italic'>'precious jewels'</span>
                    </p>
                    <img src={janetdavey} alt='Janet Davey' />
                </header>

                <section className='flex flex-col w-full items-center justify-center gap-10 mt-40'>
                    <h2 className='text-lg font-bold'>Discovery</h2>
                    <iframe
                        width='560'
                        height='315'
                        src='https://www.youtube.com/embed/5oSmJhQFFqE'
                        frameborder='0'
                        allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                        allowfullscreen
                    ></iframe>
                </section>

                <section className='flex flex-col w-full items-center justify-center gap-10 my-40'>
                    <h2 className='text-lg font-bold'>
                        Another World is Waiting
                    </h2>
                    <iframe
                        width='560'
                        height='315'
                        src='https://www.youtube.com/embed/cWqGHXmRwcM'
                        frameborder='0'
                        allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                        allowfullscreen
                    ></iframe>
                </section>

                <section className='flex flex-col w-full items-center justify-center gap-10 mb-20'>
                    <h2 className='text-lg font-bold'>
                        The Shot & I am thinking
                    </h2>
                    <iframe
                        width='560'
                        height='315'
                        src='https://www.youtube.com/embed/Q-Vstyovczc'
                        frameborder='0'
                        allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                        allowfullscreen
                    ></iframe>
                </section>

                <section className='mt-40 w-full flex flex-col'>
                    <h1 className='text-2xl font-bold mb-12'> Interview</h1>
                    <h2 className='text-lg font-bold'>
                        When did you first start composing?
                    </h2>
                    <p>
                        When I was about 10 years old. It seemed the most
                        natural thing to do at the time. I grew up in a small
                        house in Finchley; my father’s Broadwood upright piano
                        in the back room, and my mother’s upright in the front.
                        Often both were played at the same time! (Fortunately
                        our elderly neighbours were very kind, and also a bit
                        deaf). I would wake up to my father practicing Bach or
                        Chopin and hear my elder brother improvising and
                        composing. My mother loved to take a break from
                        household tasks to play hymns.
                    </p>

                    <h2 className='text-lg font-bold'>
                        Did you have anything performed as a child?
                    </h2>
                    <p>
                        Well, I’m slightly embarrassed to say yes! I was
                        extremely lucky, and got a free scholarship aged 11 to
                        the Royal Academy of Music as a Junior exhibitioner. As
                        well as studying piano and oboe, playing in the
                        orchestra, singing in the choir, I was in a theory class
                        where composition was greatly encouraged. For a special
                        anniversary we were asked to write something based on
                        the four notes of the then BBC television news signature
                        tune. I chose to write a piece for 5 part choir,
                        thinking our theory class had enough singers to try it
                        out. I had a strong sense of the news always going round
                        the same issues, such as the troubles in Northern
                        Ireland, and never reaching a resolution. As The Magic
                        Roundabout always preceded the early evening broadcast,
                        I felt it fitting to pop in a bit of that theme tune
                        too. No-one was more stunned than me when the piece got
                        chosen for performance in the bi-centennial concert!
                    </p>

                    <h2 className='text-lg font-bold'>
                        Your experience at the Royal Academy must have been most
                        formative.
                    </h2>
                    <p>
                        Yes. I lived for Saturday mornings, and going to catch
                        the no 26 bus from Finchley to Baker Street. I still
                        have clear memories of wonderful experiences. As well as
                        studying piano and oboe with remarkable professors, I
                        remember several visiting conductors taking the first
                        orchestra. One in particular talked about colour and
                        texture a lot, and I wondered why the music scores
                        weren’t coloured and textured. It was at that time I
                        knew I wanted to write music, but I was also wondering
                        whether I had anything musically worthwhile to say.
                        Nadia Boulanger visited every year, and would talk about
                        the importance of true listening. I even had to play
                        part of a Mozart Piano concerto to her, and she was very
                        kind, talking about the importance of “line”.
                    </p>

                    <h2 className='text-lg font-bold'>
                        But given your enjoyment, you didn’t carry on to formal
                        music studies as an undergraduate.
                    </h2>
                    <p>
                        Well, no. Music was not taken to be a worthwhile option
                        by my school, and as I enjoyed Maths and sciences was
                        encouraged to go in that direction, taking a BSc at
                        Bedford College London, which as it happened was just
                        round the corner from the Royal Academy of Music.
                    </p>

                    <h2 className='text-lg font-bold'>
                        And from there, you went to work for BBC Radio.
                    </h2>
                    <p>
                        Yes. I joined as a trainee studio manager, opting to
                        work for the World Service and the then 44 language
                        services. I found my musical background to be a colossal
                        help. And still actively used it when later a radio
                        producer, interviewer and presenter. To me, so much of
                        radio broadcasting and communication is about sound
                        textures and the musicality of language. It is also
                        partly why I enjoy using recorded sound in some of my
                        compositions today.
                    </p>

                    <h2 className='text-lg font-bold'>
                        And why you made the transition from radio broadcasting
                        back to composition?
                    </h2>
                    <p>
                        Yes, and also wanting to be around for our three
                        children, and elderly parents. I also felt, given my
                        experiences in broadcasting, more ready with something
                        compositionally to say.
                    </p>

                    <h2 className='text-lg font-bold'>
                        Quite a few of your compositions seem to come from the
                        perspective of a child, or the elderly.
                    </h2>
                    <p>
                        It seemed a very natural thing for me to do. I love
                        working through music with the very young, and the
                        elderly. My miniature Recollection for Spectrum 4 ABRSM
                        was inspired by an interview I had with an elderly
                        gentleman in a care home. He kept returning to the most
                        salient important things in his life, and the piece
                        simply encourages the player to do the same musically.
                        Also a Spectrum duet is largely inspired by my own
                        mother living with Alzheimer’s.
                    </p>

                    <h2 className='text-lg font-bold'>
                        And now you also write poetry?
                    </h2>
                    <p>
                        Well, yes; I don’t see this as a departure from
                        composition at all, more as an extension. The poems are
                        very much based on the musicality of language, and I am
                        now experimenting with vocal sound and instrumentation.
                    </p>
                </section>

                <section className='w-full flex flex-col'>
                    <section className='my-40 w-full flex flex-col mb-40'>
                        <h1 className='text-2xl font-bold mb-12'> Contact</h1>
                        <a href='mailto:jan.davey@talk21.com'>
                            <h2 className='text-lg font-bold'>
                                jan.davey@talk21.com
                            </h2>
                        </a>
                    </section>
                </section>
            </div>
        </div>
    );
}

export default App;
